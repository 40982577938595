.autocomplete_from,
.autocomplete_to_multicity {
    list-style-type: none;
    min-height: 50px;
    overflow-y: scroll;
    position: absolute;
    padding: 10px;
    background-color: white;
    z-index: 99;
    width: 255px;
    cursor: pointer;
    box-shadow: 2px 4px 11px lightgrey;
}

.autocomplete_to {
    list-style-type: none;
    min-height: 50px !important;
    overflow-y: scroll;
    position: absolute;
    padding: 10px;
    background-color: white;
    z-index: 99;
    width: 255px;
    cursor: pointer;
    box-shadow: 2px 4px 11px lightgrey;
    top: 124px;
}

.autocomplete_from {
    top: 38px;
}

.autocomplete_to_multicity {
    top: 38px;
}

.autocomplete_from li,
.autocomplete_to_multicity li,
.autocomplete_to li {
    line-height: 22px;
    font-size: 14px;
}

.autocomplete_from li i,
.autocomplete_to_multicity li i,
.autocomplete_to li i {
    margin-right: 10px;
}

.autocomplete_from li p,
.autocomplete_to_multicity li p,
.autocomplete_to li p {
    font-size: 12px;
    padding-left: 26px;
}

.custom_modal5 .modal-content .modal-header .directions_box {
    bottom: -73px;
}
.instagram_img {
    height: auto;
}

.form-check .form-check-input {
    border-color: #6c6c6c;
    width: 24px;
    height: 24px;
    border-radius: 0 !important;
    background-color: transparent;
    cursor: pointer;
}

/* Flight Add another button style changes */
.addFlightRow {
    color: #4da4e4;
    border: none;
    background: none;
}
.removeFilghtRow {
    color: #4da4e4;
    border: none;
    background: none;
}

.flex-end {
    display: flex;
    justify-content: flex-end;
}

.showSearchBox .autocomplete_from,
.showSearchBox .autocomplete_to_multicity {
    display: block;
}

.hideSearchBox .autocomplete_from,
.hideSearchBox .autocomplete_to_multicity {
    display: none;
}
.label-radio-cursor{
    cursor: pointer;
}
.pass_span{
    margin-right: 10px;
}
.whatsapp_link {
    position: fixed;
    z-index: 9999999999999999999999;
    bottom: 50px;
    right: 20px;
    background: #25d366;
    width: 50px;
    height: 50px;
    display: block;
    text-decoration: none;
    -webkit-border-radius: 55px;
    -moz-border-radius: 55px;
    border-radius: 55px;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    text-align: center;
    padding: 10px;
  }
  .whatsapp_link i {
    color: #fff;
    font-size: 30px;
  }
//----------------- Modal Container - START -------------------//
.modal-body {
    // width: 761px;
    margin: auto;
    .detailModalContainer {
        width: 761px;
        margin-left: auto;
        margin-right: auto;

        .modalContainer {
            // background: var(--primary-hover-0069d9) 0% 0% no-repeat padding-box;
            background: #137cdb 0% 0% no-repeat padding-box;
            border-radius: 15px;
            width: 761px;
            // width: 100%;
            height: auto;
            margin: auto;

            // background-image: "../assets/images/detail_modal_bg.png";
            // background: transparent url("/public/images/detail_modal_bg.png") 0% 0%
            //   no-repeat padding-box;
            // mix-blend-mode: luminosity;
            // opacity: 0.16;

            .header {
                display: flex;
                justify-content: space-around;
                padding-top: 18px;
                margin-bottom: 19px;
                align-items: center;

                .passInfo {
                    text-align: center;
                    // font: normal normal normal 18px/24px Roboto Mono;
                    font-family: Roboto Mono;
                    font-size: 24px;
                    letter-spacing: 0px;
                    color: #ffffff;
                    opacity: 0.56;
                }
                .class {
                    width: auto;
                    display: flex;
                    justify-content: space-around;
                    align-items: center;

                    .label {
                        text-align: left;
                        // font: normal normal normal 13px/18px Roboto Mono;
                        font-family: Roboto Mono;
                        font-size: 13px;
                        letter-spacing: 0px;
                        color: #b0cfe9;
                        text-transform: capitalize;
                        opacity: 1;
                        font-weight: 600;
                        margin-right: 20px;
                    }
                    .valueHolder {
                        background: #01ffdd 0% 0% no-repeat padding-box;
                        border: 2px solid #01ffdd;
                        border-radius: 30px;
                        opacity: 1;
                        padding: 10px;

                        width: auto;
                        height: 25px;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .value {
                            text-align: left;
                            // font: normal normal medium 13px/18px Roboto Mono;
                            font-size: 13px;
                            font-family: Roboto Mono;
                            letter-spacing: 0px;
                            color: #004685;
                            text-transform: capitalize;
                            opacity: 1;
                            font-weight: 600;
                        }
                    }
                }
            }

            .contentArea {
                display: flex;
                .bar {
                    width: 41px;
                    height: 176px;
                    margin-left: 29px;
                    margin-right: 35px;
                    padding-top: 28px;
                    img {
                        width: 42px;
                    }
                }
                .content {
                    display: flex;
                    flex-direction: column;
                    width: 87%;

                    .bgMix {
                        // mix-blend-mode: "luminosity";
                        padding: 0px 18px;
                    }

                    .routeContainer {
                        margin-bottom: 20px;
                    }

                    .route {
                        display: flex;
                        padding-right: 24px;
                        padding-top: 28px;
                        height: 90px;
                        align-items: center;

                        .number {
                            background: #01ffdd 0% 0% no-repeat padding-box;
                            border: 2px solid #01ffdd;
                            border-radius: 25px;
                            opacity: 1;
                            padding: 6px;
                            margin-top: 20px;
                            margin-right: 20px;

                            width: auto;
                            height: 25px;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            font-family: Roboto Mono;
                            font-size: 13px;
                            font-weight: 600;
                            color: #004685;
                        }

                        .from {
                            display: flex;
                            flex-direction: column;
                            width: 125px;
                        }
                        .from_to {
                            margin-left: 22px;
                            margin-right: 22px;
                            width: 225px;
                        }
                        .to {
                            display: flex;
                            flex-direction: column;
                            width: 125px;
                        }
                        .date {
                            display: flex;
                            flex-direction: column;
                            width: 110px;

                            .label {
                                text-align: left;
                                font-family: Roboto Mono;
                                font-size: 11px;
                                letter-spacing: 0px;
                                color: #b0cfe9;
                                text-transform: capitalize;
                                opacity: 1;
                                line-height: 20px;
                            }
                            .value {
                                text-align: left;
                                font-family: Roboto Mono;
                                font-size: 13px;
                                letter-spacing: 0px;
                                color: #ffffff;
                                text-transform: capitalize;
                                opacity: 1;
                                font-weight: 600;
                            }
                        }

                        .title {
                            text-align: left;
                            font-family: Roboto Mono;
                            font-size: 13px;
                            letter-spacing: 0px;
                            color: #b0cfe9;
                            text-transform: capitalize;
                            opacity: 1;
                            line-height: 14px;
                        }
                        .code {
                            text-align: left;
                            font-family: Roboto Mono;
                            font-size: 35px;
                            letter-spacing: 1.05px;
                            color: #ffffff;
                            text-transform: capitalize;
                            opacity: 1;
                            font-weight: 600;
                        }
                    }
                    .dates {
                        display: flex;
                        padding-right: 24px;
                        padding-top: 20px;
                        height: 90px;

                        .from {
                            display: flex;
                            flex-direction: column;
                        }
                        .from_to {
                            margin-left: 22px;
                            margin-right: 22px;
                            width: 275px;
                        }
                        .to {
                            display: flex;
                            flex-direction: column;
                        }

                        .label {
                            text-align: left;
                            font-family: Roboto Mono;
                            font-size: 11px;
                            letter-spacing: 0px;
                            color: #b0cfe9;
                            text-transform: capitalize;
                            opacity: 1;
                            line-height: 20px;
                        }
                        .value {
                            text-align: left;
                            font-family: Roboto Mono;
                            font-size: 13px;
                            letter-spacing: 0px;
                            color: #ffffff;
                            text-transform: capitalize;
                            opacity: 1;
                            font-weight: 600;
                        }
                    }
                    .travelerDetail {
                        display: flex;
                        padding-right: 24px;
                        padding-top: 0px;
                        height: 75px;
                        align-items: center;
                        margin-bottom: 20px;

                        .from {
                            display: flex;
                            flex-direction: column;
                            width: 200px;
                        }
                        .from_to {
                            margin-left: 22px;
                            margin-right: 22px;
                            width: 198px;
                        }
                        .to {
                            display: flex;
                            flex-direction: column;
                            width: 180px;
                        }

                        .label {
                            text-align: left;
                            font-family: Roboto Mono;
                            font-size: 11px;
                            letter-spacing: 0px;
                            color: #b0cfe9;
                            text-transform: capitalize;
                            opacity: 1;
                            line-height: 20px;
                        }
                        .value {
                            text-align: left;
                            font-family: Roboto Mono;
                            font-size: 13px;
                            letter-spacing: 0px;
                            color: #ffffff;
                            text-transform: capitalize;
                            opacity: 1;
                            font-weight: 600;
                        }
                    }
                }
            }
        }

        .heading {
            text-align: left;
            font-family: Roboto;
            font-size: 22px;
            letter-spacing: 0px;
            color: #014e95;
            opacity: 1;

            margin-top: 22px;
        }

        .formContainer {
            background: #ffffff 0% 0% no-repeat padding-box;
            border-radius: 10px;
            opacity: 1;

            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 15px 15px;
            margin-top: 10px;

            .form-floating {
                margin-right: 10px;
            }

            .form-control {
                border-bottom: 1px solid #707070 !important;
            }

            .greenBtn {
                background: #3ac8a5 0% 0% no-repeat padding-box;
                border-radius: 30px;
                text-align: center;
                font-family: Roboto Slab;
                font-size: 18px;
                letter-spacing: 0px;
                color: #ffffff;
                opacity: 1;
                padding: 5px 20px;
                &:disabled {
                    background: lightgray 0% 0% no-repeat padding-box;
                }
            }
        }

        .checkBox {
            margin-left: 10px;
            margin-top: 20px;
        }

        .privacyContainer {
            display: flex;
            justify-content: end;
            align-items: center;
            margin-top: 20px;

            .text {
                text-align: center;
                font-family: Roboto;
                font-size: 12px;
                letter-spacing: 0.14px;
                color: #404040;
                opacity: 1;
                width: 80px;
            }
            .icon {
                margin-left: 20px;
            }
        }
    }
}
//----------------- Modal Container - END -------------------//
input[type="date"] {
    cursor: pointer;
}
input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}


@media only screen and (max-width: 540px) {
    #main_banner {
        .carousel-item {
                .mobile_main_banner_img {
                    display: block !important;
                    object-position: bottom;
                }
        }
    }

    .mobile_fixed_quote_btn {
        display: block;
    }




}
